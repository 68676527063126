import React from "react"
import * as Icon from "react-feather"

const horizontalMenuConfig = [
  {
    id: "atividades",
    title: "Atividades",
    type: "item",
    icon: <Icon.Activity size={20} />,
    permissions: ["admin", "user", "mod"],
    navLink: "/activity/list",
  },
  {
    id: "cliente",
    title: "Clientes",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: ["admin", "user", "mod"],
    navLink: "/client/list",
  },
  {
    id: "requester",
    title: "Solicitantes",
    type: "item",
    icon: <Icon.PhoneIncoming size={20} />,
    permissions: ["admin", "user", "mod"],
    navLink: "/requester/list",
  }, 

  {
    id: "consultor",
    title: "Consultores",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: ["admin", "mod"],
    navLink: "/user/new",
  },   
  {
    id: "report",
    title: "Relatórios",
    type: "item",
    icon: <Icon.Book size={20} />,
    permissions: ["admin", "mod"],
    navLink: "/reports/report",
  },           
]

export default horizontalMenuConfig
