export function signInRequest(email, password){
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { email, password },
  };
}

export function signInSuccess(token, user){
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, user },
  };
}

export function signUpRequest(name, email, password){
  return {
    type: '@auth/SIGN_UP_REQUEST',
    payload: { name, email, password},
  };
}

export function signFailure(){
  return {
    type: '@auth/SIGN_FAILURE',
  };
}

export function signOut() {
  console.log('action')
  return{
    type: '@auth/SIGN_OUT',
  }

}

export const changeRole = role => {
  return dispatch => dispatch({ type: "CHANGE_ROLE", userRole: role })
}
